//サイズ
$spSize:640px;
$tbSize:1120px;
$pcSize:1200px;

//break point
@mixin mq-sp {
  @media screen and (max-width: $spSize) {
    @content;
  }
}

@mixin mq-pc-tb {
  @media screen and (min-width: ($spSize - 1px)) {
    @content;
  }
}

@mixin mq-tb {
  @media screen and (min-width: ($spSize + 1px)) and (max-width: $tbSize) {
    @content;
  }
}

@mixin mq-pc {
  @media screen and (min-width: ($tbSize + 1px)) and (max-width: ($pcSize - 1px)) {
    @content;
  }
}

@mixin mq-pc-l {
  @media screen and (min-width: $pcSize) {
    @content;
  }
}
