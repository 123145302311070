@charset "UTF-8";
@import "core/brackpoint";
@import "core/font";


._conductor{
  padding:85px 0;

  @include mq-sp {
    padding: 0 5% 8%;
  };
  .sp-non{
    @include mq-sp {
      display: none!important;
    };
  }
  .pc-non{
    @include mq-pc-tb {
      display: none!important;
    };
  }
  ._inner{
    display: flex;
    justify-content: space-between;
    padding: 40px;
    width: 100%;
    max-width:980px;
    background: #C7DFF3;
    margin: 0 auto;
    box-sizing: border-box;

    @include mq-sp {
      flex-direction: column;
      padding: 0;
    };
  }
  figure{
    width: 55%;
    margin: 0;
    padding: 0;

    @include mq-sp {
      width: 90%;
      margin: 0 auto;

    };
    img{
      width: 100%;
    }
  }
  h2{
    color: #023780;
    font-weight: bold;
    font-size: 22px;
    text-align: left;

    @include mq-sp {
      &._pdg{
        padding: 5% 0;
      }
      font-size: 4.2vw;
      letter-spacing: 0;
      text-align: center;
    };


  }
  ._textField{
    width: 40%;
    padding: 1.3rem 0 1.8rem;

    @include mq-sp {
      width: 90%;
      margin: 0 auto;
    };


    p{
      font-style: 14px;
      text-align: left;
      margin-top: 1.2rem;
      line-height: 1.6;
      @include mq-sp {
        margin-top: 0;
        font-size: 3vw;
      }
    }
    ._button{
      margin-top: 1.5rem;
      a{
        margin: 0 auto;
        padding: 0.8rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        border: 1px solid #757575;

        &:before{
          margin-right: 10px;
          content: "▶︎";
          display: inline-block;
        }
      }
    }


  }
}



// 2022.01.19 --- 課題184
.other-content {
  padding-top: 2em;
  @include mq-sp {
    padding-bottom: 4em;
  };
  @include mq-pc-tb {
    padding-bottom: 10em;
  };

  .title-01, .title-02, .title-01 span, .title-02 span {
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    line-height: 1.4;
    color: #000;
  }
  .title-01 span, .title-02 span {
    display: block;
  }
  .title-01 .jp, .title-02 .jp {
    color: #666;
    font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
    @include mq-sp {
      font-size: 13px !important;
    };
    @include mq-pc-tb {
      font-size: 14px !important;
    };
  }

  .title-01 {
    letter-spacing: 0.3em;
    @include mq-sp {
      margin-bottom: 3em;
    };
    @include mq-pc-tb {
      margin-bottom: 6em;
    };
    .en {
      @include mq-sp {
        font-size: 28px;
      };
      @include mq-pc-tb {
        font-size: 40px;
      };
    }
  }

  .other-content-inner {
    @include mq-sp {
      max-width: 90vw;
      width: 100%;
      margin: 0 auto;
    };
    @include mq-pc-tb {
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    };
    a {
      position: relative;
      display: flex;
      &::before {
        content: "▶︎";
        position: absolute;
        transform: translateY(-50%);
        @include mq-sp {
          top: 25%;
          right: 0.5em;
        };
        @include mq-pc-tb {
          top: 50%;
          right: 1em;
        };
      }
      @include mq-sp {
        & + a {
          margin-top: 5vw;
        }
      };
      @include mq-pc-tb {
        width: calc((100% - 2%) / 2) !important;
      };
      &.-terrace {
        background-color: #FAD4C5;
      }
      &.-cuisine {
        background-color: #D3E6F6;
      }
      &.-chapel {
        background-color: #D3E6F5;
      }
      &.-party {
        background-color: #FDF3D7;
      }
      &.-dress {
        background-color: #FAD4C5;
      }
      &.-report {
        background-color: #fdf4d7;
      }
      &.-facility {
        background-color: #FAD4C5;
      }

      figure {
        margin: 0;
        padding: 0;
        @include mq-sp {
          width: 125px;
          height: 115px;
        };
        @include mq-pc-tb {
          width: 220px;
          height: 180px;
        };
        img {
          display: block;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .text-area {
        flex: 1;
        @include mq-pc-tb {
          padding: 0 1em;
        };
      }

      .title-02 {
        margin-top: 1em;
        margin-bottom: 0.75em;
        letter-spacing: 0.15em;
        @include mq-sp {
          line-height: 1.3;
          font-size: 16px;
        };
        @include mq-pc-tb {
          font-size: 26px;
        };
        span {
          @include mq-sp {
            font-size: 10px !important;
          };
          @include mq-pc-tb {
            font-size: 13px !important;
          };
        }
      }
      p {
        text-align: center;
        @include mq-sp {
          line-height: 1.4;
          font-size: 12px !important;
        };
        @include mq-pc-tb {
          line-height: 1.8;
        };
      }
      //.more-button {
      //  margin: auto auto 0 auto;
      //  display: inline-flex;
      //  align-items: center;
      //  justify-content: center;
      //  width: 80%;
      //  max-width: 400px;
      //  font-weight: bold;
      //  border: 1px solid #AD9268;
      //  color: #AD9268;
      //  @include mq-sp {
      //    padding: 0.8rem;
      //    font-size: 14px;
      //  };
      //  @include mq-pc-tb {
      //    padding: 1.2rem 0;
      //    font-size: 16px;
      //  };
      //  &::before {
      //    margin-right: 10px;
      //    content: "▶︎";
      //    display: inline-block;
      //  }
      //}
    }
  }
}



//2022.07.26 add 課題212
footer .nav_wrap nav.global a {
  padding: 0 20px !important;
}
.drawer_menu .content .flexwrap a:nth-child(2),
.drawer_menu .content .flexwrap a:nth-child(3) {
  background-color: #fff !important;
}
.drawer_menu .content .flexwrap a:nth-child(1) {
  background-color: #D3E6F6;
}



footer .nav_wrap nav.global {
  display: flex;
  a {
    flex: 1;
    padding: 0 !important;
  }
}
@include mq-sp {
	/*
  .drawer_menu .content .flexwrap a:last-child {
    width: 100%;
    border-top: 0;
    border-right: solid 1px #000;
  }
	*/
};


//2023.01.19 add 課題234
@include mq-pc {
  footer .bg_wrap .content .btnwrap._center{
		justify-content: center;
		a{
			margin: 0 10px;
		}
	}
};
@include mq-pc-l {
  footer .bg_wrap .content .btnwrap._center{
		justify-content: center;
		a{
			margin: 0 10px;
		}
	}
};
header .fixed_header .content .right._w40{
	width: 40%;
}

header .fixed_header .content .right .btnwrap a.flatbtn {
  background-color: #D3E6F6;
  color: #000;
}
header .fixed_header .content .right .btnwrap a {
  display: block;
  color: #FFF;
  padding: 7px 20px;
  position: relative;
  margin-left: 10px;
  align-self: center;
}
header .fixed_header .content .right .btnwrap a.flatbtn:last-child,
header .top_header .top_line .content .btnwrap a.flatbtn:last-child,
header .fixed_header .content .right .btnwrap a.flatbtn.btn_fair,
header .top_header .top_line .content .btnwrap a.flatbtn.btn_fair  {
  background-color: #74bfbf;
}
header .fixed_header .content .right .btnwrap a.flatbtn:last-child,
header .top_header .top_line .content .btnwrap a.flatbtn:last-child,
header .fixed_header .content .right .btnwrap a.flatbtn.btn_fair,
header .top_header .top_line .content .btnwrap a.flatbtn.btn_fair {
  color: #fff;
  padding: 12px 40px;
  margin-top: -10px;
  font-size: 14px;
}


//2024.01.10　課題289
/*
header .fixed_header .content .right .btnwrap,
header .top_header .top_line .content .btnwrap{
	.header_link{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 90px;
		margin-left: 10px;
		a{
			display: block;
			margin: 0;
			padding: 0;
			transform: translateY(-4px);
			&:before{
				display: none;
			}
			img{
				width: 40px;
				height: auto;
				display: block;
			}
		}
	}
}
*/
footer{
	/*
	.bg_wrap .content .logoline .contact_wrap{
		position: relative;
		.footer_link_right{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100px;
			position: absolute;
			top: 28px;
			right: 0;
			a{
				display: block;
				img{
					width: 40px;
					height: auto;
					display: block;
				}
			}
		}
	}
	*/
	.footer_link{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
		
		@include mq-pc-tb {
			display: none;
		}
		a{
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 20px;
			img{
				width: 40px;
				height: auto;
				display: block;
				margin-right: 10px;
			}
			p{
				white-space: nowrap;
				font-size: 16px;
				color: #fff;
				line-height: 1;
				@include mq-sp {
					font-size: 14px;
				}
			}
		}
	}
	.bg_wrap .content .logoline .contact_wrap{
		.footer_link_bottom{
			display: flex;
			align-items: center;
			margin-top: 20px;
			@include mq-sp {
				display: none;
			}
			a{
				display: flex;
				align-items: center;
				margin: 0 20px 0 0;
				img{
					width: 40px;
					height: auto;
					display: block;
					margin-right: 10px;
				}
				p{
					white-space: nowrap;
					font-size: 16px;
					color: #fff;
					line-height: 1;
				}
			}
		}
	}
}

.sp_nav_link{
	display: flex;
	a{
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #000;
		border-top: none;
		padding: 3.2vw;
		&:nth-child(2n){
			border-left: none;
		}
		img{
			width: 40px;
			height: auto;
			display: block;
			margin-right: 10px;
		}
		p{
			white-space: nowrap;
			font-size: 3.2vw;
			line-height: 1;
		}
	}
}


// 2024.01.31 add pet_blok //
.pet_block {
  display: flex;
  align-items: center;
  gap: 3rem;
  max-width: 980px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  @include mq-sp {
    flex-direction: column;
    gap: 1rem;
    padding: 5%;
  };
  &._concept {
    margin-top: 100px;
    margin-bottom: 100px;
    @include mq-sp {
      margin-top: 60px;
      margin-bottom: 60px;
    };
  }
  &._chapel {
    margin-bottom: 100px;
    @include mq-sp {
      margin-bottom: 60px;
    };
  }
  &._party {
    margin-top: 30px;
    margin-bottom: 80px;
    @include mq-sp {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  &._terrace {
    margin-top: 30px;
    margin-bottom: 30px;
    @include mq-sp {
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }
	figure {
		flex-basis: 45%;
    margin: 0;
    padding: 0;
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
	}
	.text_area {
    flex: 1;
    text-align: left;
    h3 {
      margin-bottom: .5em;
      color: #b68150;
      font-size: 22px;
      font-weight: bold;
      @include mq-sp {
        font-size: 18px;
      }
    }
    p {
      line-height: 1.8;
    }
	}
}

.-reverse{
  @include mq-pc-tb{
    flex-direction: row-reverse;
  }
}

  .fixed_formwrap {
    @include mq-sp{
      z-index: 10;
    }
  }


// 2024.10.21 add  //
.sp_nav_link a._nav_btn{
	display: block;
	font-size: 4.6vw;
	font-family: "Open Sans", sans-serif;
	font-weight: bold;
	span{
		display: block;
		font-size: 3.2vw;
		font-weight: normal;
	}
}
