@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700;900&display=swap");
._conductor {
  padding: 85px 0;
}

@media screen and (max-width: 640px) {
  ._conductor {
    padding: 0 5% 8%;
  }
}

@media screen and (max-width: 640px) {
  ._conductor .sp-non {
    display: none !important;
  }
}

@media screen and (min-width: 639px) {
  ._conductor .pc-non {
    display: none !important;
  }
}

._conductor ._inner {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  width: 100%;
  max-width: 980px;
  background: #C7DFF3;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 640px) {
  ._conductor ._inner {
    flex-direction: column;
    padding: 0;
  }
}

._conductor figure {
  width: 55%;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 640px) {
  ._conductor figure {
    width: 90%;
    margin: 0 auto;
  }
}

._conductor figure img {
  width: 100%;
}

._conductor h2 {
  color: #023780;
  font-weight: bold;
  font-size: 22px;
  text-align: left;
}

@media screen and (max-width: 640px) {
  ._conductor h2 {
    font-size: 4.2vw;
    letter-spacing: 0;
    text-align: center;
  }
  ._conductor h2._pdg {
    padding: 5% 0;
  }
}

._conductor ._textField {
  width: 40%;
  padding: 1.3rem 0 1.8rem;
}

@media screen and (max-width: 640px) {
  ._conductor ._textField {
    width: 90%;
    margin: 0 auto;
  }
}

._conductor ._textField p {
  font-style: 14px;
  text-align: left;
  margin-top: 1.2rem;
  line-height: 1.6;
}

@media screen and (max-width: 640px) {
  ._conductor ._textField p {
    margin-top: 0;
    font-size: 3vw;
  }
}

._conductor ._textField ._button {
  margin-top: 1.5rem;
}

._conductor ._textField ._button a {
  margin: 0 auto;
  padding: 0.8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #757575;
}

._conductor ._textField ._button a:before {
  margin-right: 10px;
  content: "▶︎";
  display: inline-block;
}

.other-content {
  padding-top: 2em;
}

@media screen and (max-width: 640px) {
  .other-content {
    padding-bottom: 4em;
  }
}

@media screen and (min-width: 639px) {
  .other-content {
    padding-bottom: 10em;
  }
}

.other-content .title-01, .other-content .title-02, .other-content .title-01 span, .other-content .title-02 span {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  line-height: 1.4;
  color: #000;
}

.other-content .title-01 span, .other-content .title-02 span {
  display: block;
}

.other-content .title-01 .jp, .other-content .title-02 .jp {
  color: #666;
  font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
}

@media screen and (max-width: 640px) {
  .other-content .title-01 .jp, .other-content .title-02 .jp {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 639px) {
  .other-content .title-01 .jp, .other-content .title-02 .jp {
    font-size: 14px !important;
  }
}

.other-content .title-01 {
  letter-spacing: 0.3em;
}

@media screen and (max-width: 640px) {
  .other-content .title-01 {
    margin-bottom: 3em;
  }
}

@media screen and (min-width: 639px) {
  .other-content .title-01 {
    margin-bottom: 6em;
  }
}

@media screen and (max-width: 640px) {
  .other-content .title-01 .en {
    font-size: 28px;
  }
}

@media screen and (min-width: 639px) {
  .other-content .title-01 .en {
    font-size: 40px;
  }
}

@media screen and (max-width: 640px) {
  .other-content .other-content-inner {
    max-width: 90vw;
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 639px) {
  .other-content .other-content-inner {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
}

.other-content .other-content-inner a {
  position: relative;
  display: flex;
}

.other-content .other-content-inner a::before {
  content: "▶︎";
  position: absolute;
  transform: translateY(-50%);
}

@media screen and (max-width: 640px) {
  .other-content .other-content-inner a::before {
    top: 25%;
    right: 0.5em;
  }
}

@media screen and (min-width: 639px) {
  .other-content .other-content-inner a::before {
    top: 50%;
    right: 1em;
  }
}

@media screen and (max-width: 640px) {
  .other-content .other-content-inner a + a {
    margin-top: 5vw;
  }
}

@media screen and (min-width: 639px) {
  .other-content .other-content-inner a {
    width: calc((100% - 2%) / 2) !important;
  }
}

.other-content .other-content-inner a.-terrace {
  background-color: #FAD4C5;
}

.other-content .other-content-inner a.-cuisine {
  background-color: #D3E6F6;
}

.other-content .other-content-inner a.-chapel {
  background-color: #D3E6F5;
}

.other-content .other-content-inner a.-party {
  background-color: #FDF3D7;
}

.other-content .other-content-inner a.-dress {
  background-color: #FAD4C5;
}

.other-content .other-content-inner a.-report {
  background-color: #fdf4d7;
}

.other-content .other-content-inner a.-facility {
  background-color: #FAD4C5;
}

.other-content .other-content-inner a figure {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 640px) {
  .other-content .other-content-inner a figure {
    width: 125px;
    height: 115px;
  }
}

@media screen and (min-width: 639px) {
  .other-content .other-content-inner a figure {
    width: 220px;
    height: 180px;
  }
}

.other-content .other-content-inner a figure img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.other-content .other-content-inner a .text-area {
  flex: 1;
}

@media screen and (min-width: 639px) {
  .other-content .other-content-inner a .text-area {
    padding: 0 1em;
  }
}

.other-content .other-content-inner a .title-02 {
  margin-top: 1em;
  margin-bottom: 0.75em;
  letter-spacing: 0.15em;
}

@media screen and (max-width: 640px) {
  .other-content .other-content-inner a .title-02 {
    line-height: 1.3;
    font-size: 16px;
  }
}

@media screen and (min-width: 639px) {
  .other-content .other-content-inner a .title-02 {
    font-size: 26px;
  }
}

@media screen and (max-width: 640px) {
  .other-content .other-content-inner a .title-02 span {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 639px) {
  .other-content .other-content-inner a .title-02 span {
    font-size: 13px !important;
  }
}

.other-content .other-content-inner a p {
  text-align: center;
}

@media screen and (max-width: 640px) {
  .other-content .other-content-inner a p {
    line-height: 1.4;
    font-size: 12px !important;
  }
}

@media screen and (min-width: 639px) {
  .other-content .other-content-inner a p {
    line-height: 1.8;
  }
}

footer .nav_wrap nav.global a {
  padding: 0 20px !important;
}

.drawer_menu .content .flexwrap a:nth-child(2),
.drawer_menu .content .flexwrap a:nth-child(3) {
  background-color: #fff !important;
}

.drawer_menu .content .flexwrap a:nth-child(1) {
  background-color: #D3E6F6;
}

footer .nav_wrap nav.global {
  display: flex;
}

footer .nav_wrap nav.global a {
  flex: 1;
  padding: 0 !important;
}

@media screen and (max-width: 640px) {
  /*
  .drawer_menu .content .flexwrap a:last-child {
    width: 100%;
    border-top: 0;
    border-right: solid 1px #000;
  }
	*/
}

@media screen and (min-width: 1121px) and (max-width: 1199px) {
  footer .bg_wrap .content .btnwrap._center {
    justify-content: center;
  }
  footer .bg_wrap .content .btnwrap._center a {
    margin: 0 10px;
  }
}

@media screen and (min-width: 1200px) {
  footer .bg_wrap .content .btnwrap._center {
    justify-content: center;
  }
  footer .bg_wrap .content .btnwrap._center a {
    margin: 0 10px;
  }
}

header .fixed_header .content .right._w40 {
  width: 40%;
}

header .fixed_header .content .right .btnwrap a.flatbtn {
  background-color: #D3E6F6;
  color: #000;
}

header .fixed_header .content .right .btnwrap a {
  display: block;
  color: #FFF;
  padding: 7px 20px;
  position: relative;
  margin-left: 10px;
  align-self: center;
}

header .fixed_header .content .right .btnwrap a.flatbtn:last-child,
header .top_header .top_line .content .btnwrap a.flatbtn:last-child,
header .fixed_header .content .right .btnwrap a.flatbtn.btn_fair,
header .top_header .top_line .content .btnwrap a.flatbtn.btn_fair {
  background-color: #74bfbf;
}

header .fixed_header .content .right .btnwrap a.flatbtn:last-child,
header .top_header .top_line .content .btnwrap a.flatbtn:last-child,
header .fixed_header .content .right .btnwrap a.flatbtn.btn_fair,
header .top_header .top_line .content .btnwrap a.flatbtn.btn_fair {
  color: #fff;
  padding: 12px 40px;
  margin-top: -10px;
  font-size: 14px;
}

/*
header .fixed_header .content .right .btnwrap,
header .top_header .top_line .content .btnwrap{
	.header_link{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 90px;
		margin-left: 10px;
		a{
			display: block;
			margin: 0;
			padding: 0;
			transform: translateY(-4px);
			&:before{
				display: none;
			}
			img{
				width: 40px;
				height: auto;
				display: block;
			}
		}
	}
}
*/
footer {
  /*
	.bg_wrap .content .logoline .contact_wrap{
		position: relative;
		.footer_link_right{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100px;
			position: absolute;
			top: 28px;
			right: 0;
			a{
				display: block;
				img{
					width: 40px;
					height: auto;
					display: block;
				}
			}
		}
	}
	*/
}

footer .footer_link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

@media screen and (min-width: 639px) {
  footer .footer_link {
    display: none;
  }
}

footer .footer_link a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

footer .footer_link a img {
  width: 40px;
  height: auto;
  display: block;
  margin-right: 10px;
}

footer .footer_link a p {
  white-space: nowrap;
  font-size: 16px;
  color: #fff;
  line-height: 1;
}

@media screen and (max-width: 640px) {
  footer .footer_link a p {
    font-size: 14px;
  }
}

footer .bg_wrap .content .logoline .contact_wrap .footer_link_bottom {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

@media screen and (max-width: 640px) {
  footer .bg_wrap .content .logoline .contact_wrap .footer_link_bottom {
    display: none;
  }
}

footer .bg_wrap .content .logoline .contact_wrap .footer_link_bottom a {
  display: flex;
  align-items: center;
  margin: 0 20px 0 0;
}

footer .bg_wrap .content .logoline .contact_wrap .footer_link_bottom a img {
  width: 40px;
  height: auto;
  display: block;
  margin-right: 10px;
}

footer .bg_wrap .content .logoline .contact_wrap .footer_link_bottom a p {
  white-space: nowrap;
  font-size: 16px;
  color: #fff;
  line-height: 1;
}

.sp_nav_link {
  display: flex;
}

.sp_nav_link a {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-top: none;
  padding: 3.2vw;
}

.sp_nav_link a:nth-child(2n) {
  border-left: none;
}

.sp_nav_link a img {
  width: 40px;
  height: auto;
  display: block;
  margin-right: 10px;
}

.sp_nav_link a p {
  white-space: nowrap;
  font-size: 3.2vw;
  line-height: 1;
}

.pet_block {
  display: flex;
  align-items: center;
  gap: 3rem;
  max-width: 980px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

@media screen and (max-width: 640px) {
  .pet_block {
    flex-direction: column;
    gap: 1rem;
    padding: 5%;
  }
}

.pet_block._concept {
  margin-top: 100px;
  margin-bottom: 100px;
}

@media screen and (max-width: 640px) {
  .pet_block._concept {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.pet_block._chapel {
  margin-bottom: 100px;
}

@media screen and (max-width: 640px) {
  .pet_block._chapel {
    margin-bottom: 60px;
  }
}

.pet_block._party {
  margin-top: 30px;
  margin-bottom: 80px;
}

@media screen and (max-width: 640px) {
  .pet_block._party {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.pet_block._terrace {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .pet_block._terrace {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.pet_block figure {
  flex-basis: 45%;
  margin: 0;
  padding: 0;
}

.pet_block figure img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.pet_block .text_area {
  flex: 1;
  text-align: left;
}

.pet_block .text_area h3 {
  margin-bottom: .5em;
  color: #b68150;
  font-size: 22px;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .pet_block .text_area h3 {
    font-size: 18px;
  }
}

.pet_block .text_area p {
  line-height: 1.8;
}

@media screen and (min-width: 639px) {
  .-reverse {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 640px) {
  .fixed_formwrap {
    z-index: 10;
  }
}

.sp_nav_link a._nav_btn {
  display: block;
  font-size: 4.6vw;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.sp_nav_link a._nav_btn span {
  display: block;
  font-size: 3.2vw;
  font-weight: normal;
}
